import { AzureOpenAI } from "openai";


interface AssistantOptions {
  model: string;
  name: string;
  instructions: string;
  tools: any[];
  tool_resources: Record<string, any>;
  temperature: number;
  top_p: number;
}

interface AssistantResponse {
  id: string;
  [key: string]: any;
}

const azureOpenAIKey = "7fd92127cad34076ac2e114a76249f7c";
const azureOpenAIEndpoint = "https://eltropy-gpt4o-mini.openai.azure.com/";
const ASSISTANT_ID = "asst_X5QD70nHi19exmSbyyxHO4nL";


if (!azureOpenAIKey || !azureOpenAIEndpoint) {
  throw new Error(
    "Please set AZURE_OPENAI_KEY and AZURE_OPENAI_ENDPOINT in your environment variables."
  );
}


let activeThreadId: string | null = null;


const getClient = (): AzureOpenAI => {
  return new AzureOpenAI({
    endpoint: azureOpenAIEndpoint,
    apiVersion: "2024-05-01-preview",
    apiKey: azureOpenAIKey,
    dangerouslyAllowBrowser: true
  });
};

const assistantsClient = getClient();

const options: AssistantOptions = {
  model: "gpt-4o-mini-deployment-dev",
  name: "Video Banking Assistant Demo",
  instructions:
    "You are a banking assistant named Ava, You can answer about loans and cards. The routing number is 12341234",
  tools: [],
  tool_resources: {},
  temperature: 0.01,
  top_p: 1,
};

let assistantId: string | null = null;

export const setupAssistant = async (): Promise<AssistantResponse> => {
  try {
    const assistantResponse = await assistantsClient.beta.assistants.create(
      options
    );
    assistantId = assistantResponse.id;
    console.log(`Assistant created: ${JSON.stringify(assistantResponse)}`);
    return assistantResponse;
  } catch (error: any) {
    console.error(`Error creating assistant: ${error.message}`);
    throw error;
  }
};

export const runAssistant = async (message: string): Promise<string> => {
  try {
    console.log("Running assistant with message:", message);
    const assistantsClient = getClient();

    // Create a new thread
    if (!activeThreadId) {
      const thread = await assistantsClient.beta.threads.create({});
      activeThreadId = thread.id;
      console.log("New thread created:", thread);
    }

    // Use the existing thread
    await assistantsClient.beta.threads.messages.create(activeThreadId, {
      role: "user",
      content: message,
    });
    console.log("Message sent to assistant:", message);

    const run = await assistantsClient.beta.threads.runs.create(activeThreadId, {
      assistant_id: ASSISTANT_ID,
    });
    console.log("Run started:", run);

    let runStatus = run.status;
    while (runStatus === "queued" || runStatus === "in_progress") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const statusResponse = await assistantsClient.beta.threads.runs.retrieve(
        activeThreadId,
        run.id
      );
      runStatus = statusResponse.status;
      console.log("Run status:", runStatus);
    }

    if (runStatus === "completed") {
      const messages = await assistantsClient.beta.threads.messages.list(activeThreadId);
      const response = messages.data.find(msg => msg.role === "assistant");
      console.log("Assistant response:", response);

      if (response?.content[0]?.type === "text") {
        return response.content[0].text.value;
      }
    }
    
    return "I'm sorry, I couldn't process that request.";
  } catch (error) {
    console.error("Assistant error:", error);
    return "Sorry, there was an error processing your request.";
  }
};

export const resetConversation = () => {
  activeThreadId = null;
};
