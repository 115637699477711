import { useState, useEffect, useCallback, useRef } from 'react';

export const useSpeechRecognition = (isAvatarTalking: boolean = false) => {
  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const finalTranscriptRef = useRef('');
  const isSpeakingRef = useRef(false);
  const [userStoppedSpeaking, setUserStoppedSpeaking] = useState(false);

  useEffect(() => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const SpeechRecognitionConstructor = (
        window.SpeechRecognition || window.webkitSpeechRecognition
      ) as unknown as { new(): SpeechRecognition };
      
      const recognition = new SpeechRecognitionConstructor();
      
      recognition.continuous = false;
      recognition.interimResults = true;
      recognition.lang = 'en-US';

      recognition.onstart = () => {
        setIsListening(true);
        isSpeakingRef.current = false;
        finalTranscriptRef.current = '';
      };

      recognition.onresult = (event: SpeechRecognitionEvent) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }

        if (finalTranscript) {
          finalTranscriptRef.current = finalTranscript.trim();
          isSpeakingRef.current = true;
          setTranscript(finalTranscriptRef.current);
          setUserStoppedSpeaking(false);
        }

        if (interimTranscript) {
          setTranscript(
            (finalTranscriptRef.current + ' ' + interimTranscript).trim()
          );
        }

        // Set a longer timeout (3 seconds) to detect when user stops speaking
        timeoutRef.current = setTimeout(() => {
          if (finalTranscriptRef.current) {
            setUserStoppedSpeaking(true);
          }
        }, 10000); // Increased 
      };

      recognition.onend = () => {
        // Set isListening to false immediately
        setIsListening(false);
        
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // If we have a final transcript, set it as the result
        if (finalTranscriptRef.current) {
          setTranscript(finalTranscriptRef.current);
        }

        // Reset speaking state
        isSpeakingRef.current = false;
      };

      recognition.onerror = (event: any) => {
        console.error('Speech recognition error:', event.error);
        setIsListening(false);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };

      setRecognition(recognition);
    }
  }, []);

  const startListening = useCallback(() => {
    if (recognition && !isListening) {
      try {
        finalTranscriptRef.current = '';
        setTranscript('');
        isSpeakingRef.current = false;
        recognition.start();
      } catch (error) {
        console.warn('Speech recognition failed to start:', error);
        setIsListening(false);
      }
    }
  }, [recognition, isListening]);

  const stopListening = useCallback(() => {
    if (recognition && isListening) {
      try {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        recognition.stop();
      } catch (error) {
        console.warn('Speech recognition failed to stop:', error);
      }
      setIsListening(false);
    }
  }, [recognition, isListening]);

  const resetTranscript = useCallback(() => {
    setTranscript('');
    finalTranscriptRef.current = '';
    isSpeakingRef.current = false;
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (recognition && isListening) {
        recognition.stop();
      }
    };
  }, [recognition, isListening]);

  return {
    transcript,
    isListening,
    startListening,
    stopListening,
    resetTranscript,
  };
};