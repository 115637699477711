import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button, VStack, SimpleGrid, Box } from "@chakra-ui/react";
import { useState } from "react";

export const LanguagePage = ({
  initial,
  animate,
  exit,
  transition,
}: {
  initial: any;
  animate: any;
  exit: any;
  transition: any;
}) => {
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const languages = [
    { code: "en", name: "English" },
    { code: "es", name: "Spanish" },
    { code: "zh", name: "Chinese" },
    { code: "tl", name: "Tagalog" },
    { code: "vi", name: "Vietnamese" },
    { code: "ar", name: "Arabic" },
    { code: "fr", name: "French" },
    { code: "ko", name: "Korean" },
    { code: "ru", name: "Russian" },
  ];

  const handleLanguageClick = (langCode: string) => {
    setSelectedLanguage(langCode);
  };

  const handleContinue = () => {
    if (selectedLanguage) {
      navigate("/select-avatar");
    }
  };

  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
    >
      <div className="landing-container">
        <motion.div
          className="content-wrapper"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <VStack spacing={4} width="100%" maxW="800px" margin="auto">
            <motion.h1
              className="title"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.8, type: "spring" }}
              style={{ whiteSpace: "nowrap" }}
            >
              Select Language
            </motion.h1>

            <SimpleGrid columns={[1, 2, 3]} spacing={8} width="100%">
              {languages.map((lang) => (
                <Button
                  key={lang.code}
                  size="lg"
                  width="100%"
                  bg={selectedLanguage === lang.code ? "purple.500" : "white"}
                  color={selectedLanguage === lang.code ? "#E4E9FF" : "#5028ea"}
                  onClick={() => handleLanguageClick(lang.code)}
                  _hover={{
                    bg: selectedLanguage === lang.code ? "purple.600" : "white",
                    boxShadow: "0 0 15px rgba(128, 0, 128, 0.5)",
                  }}
                  boxShadow={
                    selectedLanguage === lang.code
                      ? "0 0 20px rgba(128, 0, 128, 0.6)"
                      : "none"
                  }
                  transition="all 0.3s ease"
                >
                  {lang.name}
                </Button>
              ))}
            </SimpleGrid>
            <Box mt={4}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <motion.button
                  className="enter-button"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleContinue}
                  style={{
                    opacity: selectedLanguage ? 1 : 0.5,
                    cursor: selectedLanguage ? "pointer" : "not-allowed",
                  }}
                >
                  <b>Continue</b>
                </motion.button>
              </motion.div>
            </Box>
          </VStack>
        </motion.div>
      </div>
    </motion.div>
  );
};
