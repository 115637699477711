interface Avatar {
  avatar_id: string;
  name: string;
  image: string;
}

export const AVATARS: Avatar[] = [
  {
    avatar_id: "c20f4bdddbe041ecba98d93444f8b29b",
    name: "Angelina",
    image: "https://i.ibb.co/DtKTNrN/angelina2.png"
  },
  {
    avatar_id: "2c57ba04ef4d4a5ca30a953d0791e7e3",
    name: "Aiden",
    image: "https://i.ibb.co/G24KV6h/aiden.png"
  },
  {
    avatar_id: "eb0a8cc8046f476da551a5559fbb5c82",
    name: "Raj",
    image: "https://i.ibb.co/HD3nqrj/raj.png"
  },
  {
    avatar_id: "ef08039a41354ed5a20565db899373f3",
    name: "Allie",
    image: "https://i.ibb.co/dLWhXjT/sofia.png"
  },
  {
    avatar_id: "Wayne_20240711",
    name: "Wayne",
    image: "https://i.ibb.co/QbKmsHF/wayne.png"
  },
  {
    avatar_id: "fa7b34fe0b294f02b2fca6c1ed2c7158",
    name: "Vicky",
    image: "https://i.ibb.co/vqwx6Q1/vicky.png"
  }
];

export const STT_LANGUAGE_LIST = [
  { label: 'Bulgarian', value: 'bg', key: 'bg' },
  { label: 'Chinese', value: 'zh', key: 'zh' },
  { label: 'Czech', value: 'cs', key: 'cs' },
  { label: 'Danish', value: 'da', key: 'da' },
  { label: 'Dutch', value: 'nl', key: 'nl' },
  { label: 'English', value: 'en', key: 'en' },
  { label: 'Finnish', value: 'fi', key: 'fi' },
  { label: 'French', value: 'fr', key: 'fr' },
  { label: 'German', value: 'de', key: 'de' },
  { label: 'Greek', value: 'el', key: 'el' },
  { label: 'Hindi', value: 'hi', key: 'hi' },
  { label: 'Hungarian', value: 'hu', key: 'hu' },
  { label: 'Indonesian', value: 'id', key: 'id' },
  { label: 'Italian', value: 'it', key: 'it' },
  { label: 'Japanese', value: 'ja', key: 'ja' },
  { label: 'Korean', value: 'ko', key: 'ko' },
  { label: 'Malay', value: 'ms', key: 'ms' },
  { label: 'Norwegian', value: 'no', key: 'no' },
  { label: 'Polish', value: 'pl', key: 'pl' },
  { label: 'Portuguese', value: 'pt', key: 'pt' },
  { label: 'Romanian', value: 'ro', key: 'ro' },
  { label: 'Russian', value: 'ru', key: 'ru' },
  { label: 'Slovak', value: 'sk', key: 'sk' },
  { label: 'Spanish', value: 'es', key: 'es' },
  { label: 'Swedish', value: 'sv', key: 'sv' },
  { label: 'Turkish', value: 'tr', key: 'tr' },
  { label: 'Ukrainian', value: 'uk', key: 'uk' },
  { label: 'Vietnamese', value: 'vi', key: 'vi' },
];
