import { Box, Image, Text, VStack, SimpleGrid } from "@chakra-ui/react";
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { AVATARS } from "../types/constants";

export const AvatarSelection = ({ initial, animate, exit, transition }: { 
  initial: any, 
  animate: any, 
  exit: any, 
  transition: any 
}) => {
  const navigate = useNavigate();

  const handleAvatarSelect = (avatarId: string) => {
    // Store selected avatar in localStorage
    localStorage.setItem('selectedAvatar', avatarId);
    navigate('/avatar');
  };

  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
    >
      <Box
        minHeight="100vh"
        bg="black"
        color="white"
        p={8}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <VStack spacing={8} mb={12}>
          <Text
            fontSize="4xl"
            fontFamily="Lora"
            bgGradient="linear(45deg, #ffffff, #e0e0e0)"
            bgClip="text"
            fontWeight="bold"
          >
            Choose Your Agent
          </Text>
          <Text fontSize="xl" color="whiteAlpha.800">
            Select the AI companion you'd like to interact with
          </Text>
        </VStack>

        <SimpleGrid 
          columns={{ base: 1, md: 2, lg: 3 }} 
          spacing={10}
          maxW="1100px"
          w="100%"
        >
          {AVATARS.map((avatar) => (
            <motion.div
              key={avatar.avatar_id}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Box
                as="button"
                onClick={() => handleAvatarSelect(avatar.avatar_id)}
                position="relative"
                w="100%"
                aspectRatio="1"
                borderRadius="full"
                overflow="hidden"
                bg="whiteAlpha.100"
                transition="all 0.3s"
                _hover={{
                  transform: "translateY(-5px)",
                  boxShadow: "0 0 20px rgba(80, 40, 234, 0.5)",
                }}
              >
                {/* Avatar Image */}
                <Image
                  src={avatar.image}
                  alt={avatar.name}
                  w="100%"
                  h="100%"
                  objectFit="cover"
                  transition="transform 0.3s"
                  _groupHover={{ transform: 'scale(1.1)' }}
                />
                
                {/* Gradient Overlay */}
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  bg="linear-gradient(135deg, rgba(80, 40, 234, 0.2), rgba(128, 31, 202, 0.2))"
                  zIndex={1}
                />
                
                {/* You can add avatar images here if available */}
                <VStack
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  p={4}
                  bg="rgba(0, 0, 0, 0.7)"
                  spacing={1}
                  zIndex={2}
                >
                  <Text fontSize="xl" fontWeight="bold">
                    {avatar.name}
                  </Text>
                </VStack>
              </Box>
            </motion.div>
          ))}
        </SimpleGrid>
      </Box>
    </motion.div>
  );
};