import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../LandingPage.css';
import { Box, Image } from "@chakra-ui/react";
import logo from './logo.png';

export const LandingPage = ({ initial, animate, exit, transition }: { initial: any, animate: any, exit: any, transition: any }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
    >
      <div className="landing-container">
        <motion.div 
          className="content-wrapper"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8 }}
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Image src={logo} alt="Logo" width="420px" mb={5} />
          </motion.div>

          <motion.h1 
            className="title"
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.8, type: "spring" }}
          >
            Welcome to AI Avatar Experience
          </motion.h1>
          
          <motion.p 
            className="subtitle"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            Step into the future of digital Conversations
          </motion.p>

          <motion.button
            className="enter-button"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.5 }}
            onClick={() => navigate('/select-language')}
          >
            <b>Enter Experience</b>
          </motion.button>
        </motion.div>

        <motion.div 
          className="background-animation"
          animate={{ 
            rotate: 360,
            scale: [1, 1.1, 1],
          }}
          transition={{ 
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
        />
      </div>
    </motion.div>
  );
};