import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PhoneIcon, ChatIcon } from "@chakra-ui/icons";
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaVideo,
  FaVideoSlash,
  FaStop,
} from "react-icons/fa";
import { StartAvatarResponse } from "@heygen/streaming-avatar";
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  TaskMode,
  TaskType,
  VoiceEmotion,
} from "@heygen/streaming-avatar";
import { useEffect, useRef, useState, useMemo } from "react";
import { motion } from "framer-motion";
import {
  resetConversation,
  runAssistant,
} from "../services/azureOpenAIService";
import { useSpeechRecognition } from "../hooks/useSpeecRecognition";
import { AVATARS } from "../types/constants";
import { keyframes } from "@emotion/react";
import { useNavigate } from 'react-router-dom';

declare global {
  interface HTMLCanvasElement {
    glContext?: WebGLRenderingContext | WebGL2RenderingContext;
    glProgram?: WebGLProgram;
  }

  var Image: {
    new (width?: number, height?: number): HTMLImageElement;
  };
}

const HEYGEN_API_KEY =
  "MWY4ZTE5OTI5NmE3NGIzMGE0MjY5OTg5YmRmMDg3MTAtMTczMDQzMTU0Mw==";

const formatText = (text: string) => {
  return text.split(/(\*\*.*?\*\*)/).map((part, index) => {
    if (part.startsWith("**") && part.endsWith("**")) {
      return (
        <Text as="span" key={index} fontWeight="bold">
          {part.slice(2, -2)}
        </Text>
      );
    }
    return (
      <Text as="span" key={index}>
        {part}
      </Text>
    );
  });
};

// List of filler sentences (commented out but preserved)
/* const fillerSentences = [
  "Let me get that for you.",
  "Sure, let me check.",
  "One moment, please!",
  "I'll find that information for you.",
  "Please hold on while I look that up!",
  "Let me check that.",
  "Just a sec.",
  "Working on it now.",
  "Almost done.",
  "Hang tight!",
  "Just a moment.",
  "Checking that now.",
  "Got it, processing.",
  "Alright, let me take a quick look for you.",
  "Just a second, I'm pulling up your details now.",
  "Okay, let me sort that out for you real quick.",
  "Hold on for just a moment while I check this.",
  "I'm getting everything ready for you—hang tight!",
  "Alright, give me a second to get that sorted.",
  "Thanks for waiting, I'm almost done here.",
  "Let me double-check that for you real quick.",
  "Just a moment, I'm making sure everything's good to go.",
  "Alright, I'm on it—won't take long.",
]; */

// Function to check if the transcript is a simple greeting
const isGreeting = (transcript: string) => {
  const greetings = [
    "hello",
    "hi",
    "hey",
    "bye",
    "goodbye",
    "thank",
    "thank you very much",
    "thank you very much!",
    "no",
    "no thank you",
    "no thank you!",
  ];
  return greetings.includes(transcript.toLowerCase().trim());
};

// Add this gradient animation keyframe near the top of the file
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Add new interface for message structure
interface Message {
  sender: 'user' | 'avatar';
  text: string;
}

// Add these new keyframes for the sound wave animation
const wave = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.3); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
`;

// Update the SoundWave component with smaller dimensions
const SoundWave = () => (
  <Flex
    position="absolute"
    bottom="10px"
    right="10px"
    gap="2px"  // Reduced gap between bars
    zIndex={3}
    alignItems="center"
    bg="rgba(0, 0, 0, 0.6)"
    backdropFilter="blur(10px)"
    px={2}     // Reduced horizontal padding
    py={1}     // Kept vertical padding
    borderRadius="md"
  >
    {[...Array(4)].map((_, i) => (
      <Box
        key={i}
        width="2px"    // Reduced width from 4px
        height="10px"  // Reduced height from 16px
        bg="red.500"
        borderRadius="full"
        animation={`${wave} 1s ease-in-out infinite ${i * 0.1}s`}
      />
    ))}
  </Flex>
);

export const SessionManager = ({
  initial,
  animate,
  exit,
  transition,
}: {
  initial: any;
  animate: any;
  exit: any;
  transition: any;
}) => {
  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const [isLoadingRepeat, setIsLoadingRepeat] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [debug, setDebug] = useState<string>();
  const [knowledgeId, setKnowledgeId] = useState<string>("");
  const [avatarId, setAvatarId] = useState<string>("");
  const [language, setLanguage] = useState<string>("en");
  const [data, setData] = useState<StartAvatarResponse>();
  const [text, setText] = useState<string>("");
  const mediaStream = useRef<HTMLVideoElement>(null);
  const avatar = useRef<StreamingAvatar | null>(null);
  const [chatMode, setChatMode] = useState("voice_mode");
  const [isUserTalking, setIsUserTalking] = useState(false);
  const [removeBG] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showBackground, setShowBackground] = useState(false);
  const [showTextInput, setShowTextInput] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [assistantResponse, setAssistantResponse] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState(false);
  const {
    transcript,
    isListening,
    startListening,
    stopListening,
    resetTranscript,
  } = useSpeechRecognition(isUserTalking);
  const [isAvatarTalking, setIsAvatarTalking] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [currentTranscript, setCurrentTranscript] = useState<string>("");
  const [showThankYou, setShowThankYou] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(
    localStorage.getItem("selectedAvatar") || AVATARS[0].avatar_id
  );
  const [showAvatarTranscript, setShowAvatarTranscript] = useState(false);
  const navigate = useNavigate();

  // Update state to use array of messages instead of string
  const [messages, setMessages] = useState<Message[]>([]);

  // Add this ref for the transcript container
  const transcriptRef = useRef<HTMLDivElement>(null);

  const [isInitialResponseDone, setIsInitialResponseDone] = useState(false);

  const getSelectedAvatarName = () => {
    const avatar = AVATARS.find((a) => a.avatar_id === selectedAvatar);
    return avatar ? avatar.name : "Avatar";
  };

  async function fetchAccessToken() {
    try {
      const res = await fetch(
        "https://api.heygen.com/v1/streaming.create_token",
        {
          method: "POST",
          headers: { "x-api-key": HEYGEN_API_KEY || "" },
        }
      );
      const data = await res.json();
      const token = data.data.token;
      console.log("Access Token:", token);
      return token;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
    return "";
  }

  async function startSession() {
    setIsLoadingSession(true);
    setShowBackground(true);

    const newToken = await fetchAccessToken();

    avatar.current = new StreamingAvatar({
      token: newToken,
    });
    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
      console.log("Avatar started talking", e);
      setIsAvatarTalking(true);
      stopListening();
      setTimeout(() => {
        setShowTranscript(true);
        setShowAvatarTranscript(true);
      }, 1000);
    });
    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
      console.log("Avatar stopped talking", e);
      setIsAvatarTalking(false);
      if (chatMode === "voice_mode" && !isProcessing) {
        setTimeout(() => {
          startListening();
        }, 1000);
      }
    });
    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      console.log("Stream disconnected");
      endSession();
    });
    avatar?.current?.on(StreamingEvents.STREAM_READY, (event) => {
      console.log(">>>>> Stream ready:", event.detail);
      setStream(event.detail);
      setIsInitialized(true);
    });

    try {
      const res = await avatar.current.createStartAvatar({
        quality: AvatarQuality.High,
        avatarName: selectedAvatar,
        knowledgeId: knowledgeId,
        voice: {
          rate: 1.5,
          emotion: VoiceEmotion.EXCITED,
        },
        language: language,
        disableIdleTimeout: true,
      });

      setData(res);
      setChatMode("voice_mode");
    } catch (error) {
      console.error("Error starting avatar session:", error);
    } finally {
      setIsLoadingSession(false);
    }
  }

  async function handleSpeak(textMsg: string) {
    console.log("handleSpeak called with:", textMsg);
    setIsLoadingRepeat(true);
    if (!avatar.current) {
      setDebug("Avatar API not initialized");
      return;
    }

    try {
      setCurrentTranscript((prev) => prev + "\n\nAvatar: " + textMsg);
      await avatar.current.speak({
        text: textMsg,
        taskType: TaskType.REPEAT,
        taskMode: TaskMode.SYNC,
      });
    } catch (e: any) {
      console.error("Error in handleSpeak:", e);
      setDebug(e.message);
    } finally {
      setIsLoadingRepeat(false);
    }
  }

  async function handleInterrupt() {
    if (!avatar.current) {
      setDebug("Avatar API not initialized");
      return;
    }
    await avatar.current.interrupt().catch((e) => {
      setDebug(e.message);
    });
  }

  async function endSession(shouldNavigate = true) {
    resetConversation();
    await avatar.current?.stopAvatar();
    setStream(undefined);
    setShowBackground(false);

    if (shouldNavigate) {
      navigate('/');
    }
  }

  const handleChangeChatMode = async (v: any) => {
    console.log("Chat mode change requested:", v);
    if (v === chatMode) return;

    if (v === "text_mode") {
      console.log("Stopping speech recognition");
      stopListening();
    } else {
      console.log("Starting speech recognition");
      if (!isAvatarTalking) {
        startListening();
      }
    }
    setChatMode(v);
    console.log("Chat mode changed to:", v);
  };

  const previousText = useMemo(() => text, [text]);

  useEffect(() => {
    if (!previousText && text) {
      avatar.current?.startListening();
    } else if (previousText && !text) {
      avatar?.current?.stopListening();
    }
  }, [text, previousText]);

  useEffect(() => {
    return () => {
      endSession(false);
    };
  }, []);

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream;
      mediaStream.current.onloadedmetadata = () => {
        mediaStream.current!.play();
        setDebug("Playing");
      };
    }
  }, [mediaStream, stream]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!stream && !isLoadingSession) {
        startSession();
      }

      // Start listening automatically if in voice mode
      if (chatMode === "voice_mode" && !isAvatarTalking && !isProcessing) {
        console.log("Starting listening on initial mount");
        startListening();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowTextInput(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  // Update handleVoiceInput to remove filler sentences
  const handleVoiceInput = async (transcript: string) => {
    setIsProcessing(true);
    stopListening();

    try {
      // Add user message
      setMessages(prev => [...prev, { sender: 'user', text: transcript }]);

      /* const words = transcript.toLowerCase().trim().split(" ");
      if (!isGreeting(words[0])) {
        const randomFiller = fillerSentences[Math.floor(Math.random() * fillerSentences.length)];
        await handleSpeak(randomFiller);
      } */

      const response = await runAssistant(transcript);
      if (!response) return;

      const cleanResponse = response.replace(/\*\*/g, "");
      setAssistantResponse(cleanResponse);
      
      // Add avatar message
      setMessages(prev => [...prev, { sender: 'avatar', text: cleanResponse }]);

      await handleSpeak(cleanResponse);
    } catch (error) {
      console.error("Error in handleVoiceInput:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  // Update handleTextInput
  const handleTextInput = async (inputText: string) => {
    try {
      // Add user message
      setMessages(prev => [...prev, { sender: 'user', text: inputText }]);

      const response = await runAssistant(inputText);
      setAssistantResponse(response);
      
      // Add avatar message
      setMessages(prev => [...prev, { sender: 'avatar', text: response }]);

      await handleSpeak(response);
    } catch (error) {
      console.error("Error sending input to assistant:", error);
    }
  };

  useEffect(() => {
    console.log("Speech Recognition Status:", {
      transcript,
      isListening,
      hasTranscript: Boolean(transcript),
      isAvatarTalking,
      isProcessing,
    });

    if (transcript && !isListening && !isAvatarTalking && !isProcessing) {
      console.log("=== Speech Recognition Flow Start ===");
      console.log("1. Raw Transcript:", transcript);
      handleVoiceInput(transcript);
      resetTranscript();
    }
  }, [transcript, isListening, isAvatarTalking, isProcessing]);

  useEffect(() => {
    const testAssistant = async () => {
      console.log("=== Testing Assistant Connection ===");
      try {
        console.log("1. Sending test message to assistant...");
        const response = await runAssistant("Hello");
        console.log("2. Test response received:", response);
        
        // Wait for stream to be initialized before speaking
        if (response && isInitialized) {
          setMessages([
            { sender: 'avatar', text: response }
          ]);
          await handleSpeak(response);
          setIsInitialResponseDone(true);
        }
        console.log("=== Assistant Connection Test Complete ===");
      } catch (error) {
        console.error("Error testing assistant:", error);
      }
    };

    // Only run test assistant when stream is initialized
    if (isInitialized) {
      testAssistant();
    }
  }, [isInitialized]); // Add isInitialized as dependency

  useEffect(() => {
    if (chatMode === "voice_mode" && !isAvatarTalking && !isProcessing) {
      console.log("Starting listening due to chat mode and avatar state");
      startListening();
    } else if (isAvatarTalking || chatMode !== "voice_mode") {
      console.log(
        "Stopping listening due to avatar talking or chat mode change"
      );
      stopListening();
    }
  }, [chatMode, isAvatarTalking, isProcessing]);

  useEffect(() => {
    if (showBackground) {
    
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false, // We don't need audio from the camera
        })
        .then((stream) => {
          const userVideo = document.getElementById(
            "userVideo"
          ) as HTMLVideoElement;
          if (userVideo) {
            userVideo.srcObject = stream;
          }
        })
        .catch((err) => {
          console.error("Error accessing camera:", err);
        });


      return () => {
        const userVideo = document.getElementById(
          "userVideo"
        ) as HTMLVideoElement;
        if (userVideo && userVideo.srcObject) {
          const stream = userVideo.srcObject as MediaStream;
          stream.getTracks().forEach((track) => track.stop());
        }
      };
    }
  }, [showBackground]); // Depend on showBackground to reinitialize when needed

  const toggleCamera = () => {
    const userVideo = document.getElementById("userVideo") as HTMLVideoElement;
    if (userVideo && userVideo.srcObject) {
      const stream = userVideo.srcObject as MediaStream;
      stream.getVideoTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setIsCameraOn(!isCameraOn);
    }
  };

  useEffect(() => {
    if (
      isInitialized &&
      chatMode === "voice_mode" &&
      !isAvatarTalking &&
      !isProcessing
    ) {
      console.log("Starting initial listening after initialization");
      startListening();
    }
  }, [isInitialized]);

  useEffect(() => {
    if (transcript) {
      setCurrentTranscript((prev) => {
        // Split the previous transcript into lines
        const lines = prev.split("\n\n");
        // Remove the previous "You:" message if it exists
        const filteredLines = lines.filter((line) => !line.startsWith("You:"));
        // Add the new message
        return [...filteredLines, `You: ${transcript}`].join("\n\n");
      });
    }
  }, [transcript]);

  const transcriptWidth = useBreakpointValue({
    base: "90%",
    sm: "90%",
    lg: "30%"
  });

  const transcriptPosition = useBreakpointValue({
    base: { 
      position: "absolute" as const,
      top: "auto",
      bottom: "420px",
      left: "1rem",
      transform: "none",
      maxHeight: "4px",
    },
    sm: { 
      position: "absolute" as const,
      top: "auto",
      bottom: "400px",
      left: "1rem",
      transform: "none",
      maxHeight: "1px",
    },
    lg: { 
      position: "absolute" as const,
      top: "1rem",
      left: "1rem",
      transform: "none",
      maxHeight: "80vh",
    }
  });

  const videoTransform = useBreakpointValue({
    base: "0%", 
    sm: "0%",
    lg: "30%"
  });

  const videoContainerWidth = useBreakpointValue({
    base: "90%",
    sm: "85%",
    lg: "55%"
  });

  const userVideoSize = useBreakpointValue({
    base: { width: "120px", height: "90px", right: "-20px", bottom: "-40px" },
    lg: { width: "200px", height: "150px", right: "-120px", bottom: "-60px" }
  });

  const scaleValue = useBreakpointValue({
    base: 0.8,
    sm: 0.85,
    lg: 1
  }) || 1;

  const marginBottomValue = useBreakpointValue({
    base: "45vh",
    lg: "0"
  }) || "0";

  const textInputWidth = useBreakpointValue({ 
    base: "140px", 
    sm: "200px", 
    lg: "260px"
  });

  const marginTopValue = useBreakpointValue({
    base: "0vh",  // for mobile
    sm: "0vh",    // for tablet
    lg: "-10vh"   // for desktop (992px and above)
  }) || "0vh";

  // Add this useEffect to handle auto-scrolling
  useEffect(() => {
    if (transcriptRef.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [messages]); // Scroll whenever messages update

  useEffect(() => {
    if (
      chatMode === "voice_mode" && 
      !isAvatarTalking && 
      !isProcessing && 
      !isListening && 
      isInitialResponseDone // Check this flag
    ) {
      console.log("Restarting listening due to state change");
      const restartTimer = setTimeout(() => {
        startListening();
      }, 1000); // Small delay to prevent rapid start/stop cycles
      
      return () => clearTimeout(restartTimer);
    }
  }, [chatMode, isAvatarTalking, isProcessing, isListening, isInitialResponseDone]);

  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
      style={{
        height: "100vh",
        overflow: "hidden",
        position: "fixed",
        width: "100%",
        top: 0,
        left: 0,
      }}
    >
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        bg="linear-gradient(225deg, #ffffff, #5028ea, #5028ea, #801fca)"
        color="black"
        p={5}
        overflow="hidden"
        position="relative"
      >
        <Flex justify="space-between" align="center" mb={4}>
          <Box>
            <Image src="https://eltropy.com/wp-content/uploads/2024/05/logo-eltropy-brand-white.svg" />
          </Box>
        </Flex>

        <Box
          position="relative"
          width="100%"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {showBackground && (
            <>
              {/* Transcript Box */}
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{
                  opacity: showTranscript ? 1 : 0,
                  x: showTranscript ? 0 : -100,
                }}
                transition={{ duration: 0.5 }}
                style={{
                  ...transcriptPosition,
                  width: transcriptWidth,
                  zIndex: 10
                }}
              >
                <Box
                  ref={transcriptRef}
                  bg="whiteAlpha.200"
                  backdropFilter="blur(10px)"
                  borderRadius="xl"
                  p={6}
                  boxShadow="lg"
                  color="white"
                  minHeight={{ base: "150px", md: "200px", lg: "400px" }}
                  maxHeight={{ base: "300px", md: "270px", lg: "700px" }}
                  overflowY="auto"
                  width="100%"
                  scrollBehavior="smooth"
                >
                  {/* <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Conversation Transcript
                  </Text> */}
                  {messages.map((message, index) => {
                    const isAvatarMessage = message.sender === 'avatar';
                    const avatarName = getSelectedAvatarName();

                    return (
                      <Box
                        key={index}
                        mb={4}
                        display="flex"
                        justifyContent={isAvatarMessage ? "flex-start" : "flex-end"}
                        width="100%"
                      >
                        <Box
                          maxW="80%"
                          bg={isAvatarMessage ? "whiteAlpha.200" : "blue.500"}
                          color="white"
                          px={4}
                          py={2}
                          borderRadius="20px"
                          borderTopLeftRadius={isAvatarMessage ? "4px" : "20px"}
                          borderTopRightRadius={isAvatarMessage ? "20px" : "4px"}
                          position="relative"
                        >
                          {isAvatarMessage && (
                            <Text 
                              fontSize="sm" 
                              fontWeight="bold" 
                              mb={1} 
                              opacity={0.8}
                            >
                              {avatarName}
                            </Text>
                          )}
                          <Text whiteSpace="pre-wrap">
                            {message.text}
                          </Text>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </motion.div>

              {/* Video Container with responsive animation */}
              <motion.div
                initial={{ x: 0 }}
                animate={{
                  x: showTranscript ? videoTransform : 0,
                  scale: showTranscript ? scaleValue : 1
                }}
                transition={{ duration: 0.5 }}
                style={{
                  width: videoContainerWidth,
                  height: "auto",
                  maxHeight: "85vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: marginTopValue,
                  marginBottom: marginBottomValue
                }}
              >
                <Box
                  position="relative"
                  width="100%"
                  height="auto"
                >
                  {/* Avatar Video */}
                  <Box
                    position="relative"
                    width="100%"
                    paddingTop="56.25%"
                    borderRadius="20px"
                    overflow="hidden"
                  >
                    {!stream && (
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        background="linear-gradient(-45deg, #5028ea, #801fca, #5028ea, #801fca)"
                        backgroundSize="400% 400%"
                        animation={`${gradientAnimation} 3s ease infinite`}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Spinner size="xl" color="white" thickness="4px" />
                      </Box>
                    )}
                    {stream && (
                      <>
                        <video
                          ref={mediaStream}
                          autoPlay
                          playsInline
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        >
                          <track kind="captions" />
                        </video>

                        {/* Avatar Name Overlay - Only shown when stream is available */}
                        <Box
                          position="absolute"
                          bottom="10px"
                          left="10px"
                          bg="rgba(0, 0, 0, 0.6)"
                          color="white"
                          px={3}
                          py={1}
                          borderRadius="md"
                          fontSize="sm"
                          fontWeight="medium"
                        >
                          {getSelectedAvatarName()}
                        </Box>
                      </>
                    )}
                  </Box>

                  {/* User Video */}
                  <Box
                    position="absolute"
                    bottom="-30px"
                    right="-30px"
                    width={{ base: "120px", md: "180px" }}
                    height={{ base: "90px", md: "135px" }}
                    borderRadius="15px"
                    overflow="hidden"
                    boxShadow="lg"
                    zIndex={2}
                  >
                    <video
                      id="userVideo"
                      autoPlay
                      playsInline
                      muted
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    >
                      <track kind="captions" />
                    </video>
                    {!isCameraOn && (
                      <Flex
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        justifyContent="center"
                        alignItems="center"
                        bg="gray.600"
                      >
                        <FaVideoSlash size="50px" color="#A0AEC0" />
                      </Flex>
                    )}
                    {isListening && !isAvatarTalking && !isProcessing && (
                      <SoundWave />
                    )}
                  </Box>
                </Box>
              </motion.div>
            </>
          )}
        </Box>

        <Box mt={4}>
          <Flex
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            p={4}
            justify="center"
            align="center"
            bg="rgba(0, 0, 0, 0.4)"
            backdropFilter="blur(10px)"
          >
            {stream && (
              <Flex gap={4} align="center">
                {/* Text Input Button/Box */}
                {showTextInput ? (
                  <motion.div
                    initial={{ width: 0, opacity: 0 }}
                    animate={{ width: textInputWidth, opacity: 1 }}
                    exit={{ width: 0, opacity: 0 }}
                  >
                    <Input
                      ref={inputRef}
                      placeholder="Type your message..."
                      bg="whiteAlpha.500"
                      border="none"
                      color="white"
                      _placeholder={{ color: "whiteAlpha.600" }}
                      maxW={textInputWidth}
                      w="100%"
                      mr={2}
                      onChange={(e) => setText(e.target.value)}
                      onKeyPress={async (e) => {
                        if (e.key === "Enter" && text.trim()) {
                          const inputText = text;
                          setText("");
                          await handleTextInput(inputText);
                        }
                      }}
                      value={text}
                      autoFocus
                    />
                  </motion.div>
                ) : (
                  <Button
                    onClick={() => setShowTextInput(true)}
                    colorScheme="red"
                    borderRadius="full"
                    size="lg"
                    w="60px"
                    h="60px"
                  >
                    <ChatIcon boxSize={6} />
                  </Button>
                )}

                {/* Mic Button */}
                <Button
                  onClick={() =>
                    handleChangeChatMode(
                      chatMode === "text_mode" ? "voice_mode" : "text_mode"
                    )
                  }
                  colorScheme={chatMode === "voice_mode" ? "red" : "gray"}
                  borderRadius="full"
                  size="lg"
                  w="60px"
                  h="60px"
                >
                  <Box fontSize="24px">
                    {chatMode === "voice_mode" ? (
                      <FaMicrophone />
                    ) : (
                      <FaMicrophoneSlash />
                    )}
                  </Box>
                </Button>

                {/* Stop Speaking Button */}
                {stream && (
                  <Button
                    onClick={handleInterrupt}
                    colorScheme="red"
                    borderRadius="full"
                    size="lg"
                    w="80px"
                    h="60px"
                  >
                    <FaStop size="24px" />
                  </Button>
                )}

                {/* Camera Button */}
                <Button
                  onClick={toggleCamera}
                  colorScheme={isCameraOn ? "red" : "gray"}
                  borderRadius="full"
                  size="lg"
                  w="60px"
                  h="60px"
                >
                  <Box fontSize="24px">
                    {isCameraOn ? <FaVideo /> : <FaVideoSlash />}
                  </Box>
                </Button>

                {/* End Call Button */}
                <Button
                  onClick={() => endSession()}
                  colorScheme="red"
                  borderRadius="full"
                  size="lg"
                  w="60px"
                  h="60px"
                >
                  <PhoneIcon boxSize={6} transform="rotate(135deg)" />
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>

        {showThankYou && !showBackground && (
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.8)",
              zIndex: 1000,
            }}
          >
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              transition={{
                duration: 0.5,
                type: "spring",
                stiffness: 300,
                damping: 20,
              }}
            >
              <Box
                bg="whiteAlpha.200"
                backdropFilter="blur(10px)"
                borderRadius="xl"
                p={8}
                textAlign="center"
                color="white"
              >
                <Text fontSize="3xl" fontWeight="bold" mb={4}>
                  Thank You!
                </Text>
                <Text fontSize="xl">Have a great day!</Text>
              </Box>
            </motion.div>
          </motion.div>
        )}
      </Box>
    </motion.div>
  );
};
