// Install dependencies before starting: npm install axios react react-dom
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './index.css';
import { SessionManager } from './components/SessionManager';
import { LandingPage } from './components/LandingPage';
import { AvatarSelection } from './components/AvatarSelection';
import { LanguagePage } from './components/LanguagePage';

const pageTransition = {
  initial: { opacity: 0, y: 30 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -30 },
  transition: {
    type: "tween",
    ease: "easeInOut",
    duration: 0.15
  }
};

// Create a wrapper component for AnimatePresence
const AnimatedRoutes = () => {
  const location = useLocation();
  
  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<LandingPage {...pageTransition} />} />
        <Route path="/select-language" element={<LanguagePage {...pageTransition} />} />
        <Route path="/select-avatar" element={<AvatarSelection {...pageTransition} />} />
        <Route path="/avatar" element={<SessionManager {...pageTransition} />} />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
};

export default App;
